<template>
  <div class="ask-home-page">
    <div class="left">
      <div class="top">
        <div class="title">功能导航</div>
        <used-menus :authFun="authFun"/>
      </div>
      <div class="bottom">
        <div class="b-left" v-if="authFun.my">
          <div class="title">我的年度请假情况</div>
          <my-ask-leave class="card"></my-ask-leave>
        </div>
        <div class="b-right" v-if="$authFunsProxy.current">
          <div class="title">当前请假情况</div>
          <current-ask-leave class="card"></current-ask-leave>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsedMenus from './home/menus'
import MyAskLeave from './home/my'
import CurrentAskLeave from './home/current'

export default {
  components: { UsedMenus, MyAskLeave, CurrentAskLeave},
  data () {
    return {
      status: {
        editForm: false
      },
      placardList: []
    }
  },
  provide () {
    return {
      authFun: this.authFun,
      authJump: this.authJump
    }
  },
  computed: {
    authFun () {
      const isAdmin = this.$store.getters.currentRole && this.$store.getters.currentRole.id === 1
      return {
        my: (isAdmin || this.$store.getters.currentFunKeys.includes('hrms.ask_leave.my-get')),
        myApply: (isAdmin || this.$store.getters.currentFunKeys.includes('hrms.ask_leave.apply-add')),
        apply: isAdmin || this.$store.getters.currentFunKeys.includes('hrms.ask_leave.apply-base'),
        applyAssign: isAdmin || this.$store.getters.currentFunKeys.includes('hrms.ask_leave.apply_assign-base'),
        off: isAdmin || this.$store.getters.currentFunKeys.includes('hrms.ask_leave.record-edit')
      }
    }
  }
}
</script>

<style scoped lang="less">
.card {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  background-color: #FFF;
}
.ask-home-page {
  background-color: #EFF1F7;
  padding: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  .left {
    width: 100%;
    height: 100%;
  }
  .title {
    font-size: 16px;
    color: #3C3F54;
    margin-bottom: 15px;
  }
  .top {
    height: 232px;
  }
  .bottom {
    height: calc(100% - 232px);
    width: 100%;
    display: flex;
  }
  .b-left {
    width: calc(50% - 10px);
    height: 100%;
    margin-right: 20px;
  }
  .b-right {
    width: calc(50% - 10px);
    height: 100%;
  }
}
</style>