<style scoped lang="less">
  .used-menus {
    overflow: auto;
    display: flex;
    position: relative;
    .item {
      width: 120px;
      transition: all .3s;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      color: #3C3F54;
      box-sizing: border-box;
      padding: 20px;
      .iconfont {
        display: inline-block;
        margin-bottom: 21px;
        height: 70px;
        width: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 30px;
        border-radius: 18px 2px 18px 18px;
        position: relative;
        .num {
          position: absolute;
          right: 5px;
          top: 5px;
          min-width: 20px;
          height: 20px;
          border-radius: 10px;
          font-size: 13px;
          color: #FFF;
          line-height: 20px;
          background-color: #EB3030;
        }
      }
      & + .item {
        margin-left: 20px;
      }
    }
  }
  .card {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    background-color: #FFF;
  }
</style>

<style lang="less">
.pink-theme {
  .used-menus {
    .item:hover {
      color: #FB6D8F !important;
    }
  }
}
.drak-theme, .blue-theme {
  .used-menus {
    .item:hover {
      color: #6B75CA !important;
    }
  }
}
</style>

<template>
  <div class="used-menus">
    <div v-if="authFun.apply" class="card item" @click="$router.push({name: 'hrms.ask_leave.apply'})">
      <i class="iconfont icon-a-zu1438" style="color: #0254FE;background: #F0F3F8;"></i>
      <div>请假条<br/>申请/审批</div>
    </div>
    <div v-if="authFun.my" class="card item" @click="$router.push({name: 'hrms.ask_leave.my'})">
      <i class="iconfont icon-copy" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>我的实际请假情况</div>
    </div>
    <!-- <div v-if="authFun.apply" class="card item" @click="$router.push({name: 'hrms.ask_leave.apply'})">
      <i class="iconfont icon-a-zu1440" style="color: #FB6D8F;background: #FEF0F1;"></i>
      <div>请假条审批</div>
    </div> -->
    <div v-if="authFun.applyAssign" class="card item" @click="$router.push({name: 'hrms.ask_leave.apply_assign'})">
      <i class="iconfont icon-jigoushezhi" style="color: #E3C700;background: #FEFAF0;"></i>
      <div>请假条分配</div>
    </div>
    <div v-if="authFun.off" class="card item" @click="$router.push({name: 'hrms.ask_leave.record'})">
      <i class="iconfont icon-copy" style="color: #4AB8FF;background: #F0F3F8;"></i>
      <div>销假</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    authFun: {
      type: Object
    }
  }
}
</script>
